<template>
    <a-card>
        <a-page-header
            :title='"线索明细"'
        />
        <div class="data-wrap">
            <div class="box">
                <div class="title">阿凡提线索ID</div>
                <div class="detail">{{ dataForm.id || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告平台</div>
                <div class="detail">{{ dataForm.platformId == 1 ? '巨量广告' : dataForm.platformId == 2 ? '磁力广告' : dataForm.platformId == 3 ? '百度信息流' : '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告平台线索ID</div>
                <div class="detail">{{ dataForm.platformClueId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title red">广告平台线索创建时间</div>
                <div class="detail">{{ dataForm.platformCtime || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">推送状态（推送线索系统）</div>
                <div class="detail">{{ dataForm.pushState == 2 ? '已推送' : '未推送' }}</div>
            </div>
            <div class="box">
                <div class="title">线索完整性</div>
                <div class="detail">{{ dataForm.isWhole == 2 ? '是' : '否' }}</div>
            </div>
            <div class="box">
                <div class="title red">姓名</div>
                <div class="detail">{{ dataForm.userName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title red">手机号</div>
                <div class="detail">{{ dataForm.phone || '-' }}</div>
            </div>
            <div class="box">
                <div class="title red">意向车系</div>
                <div class="detail">{{ dataForm.preferCarTrain || '-' }}</div>
            </div>
            <div class="box">
                <div class="title red">购车城市</div>
                <div class="detail">{{ dataForm.purchaseCity || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">集客场景</div>
                <div class="detail">{{ dataForm.gatherCustomerWay || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">品牌（主体）</div>
                <div class="detail">{{ dataForm.carTrainBrandName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">分配后广告主</div>
                <div class="detail">{{ dataForm.aassignAdvertiserName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">分配前广告主</div>
                <div class="detail">{{ dataForm.bassignAdvertiserName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">来源应用</div>
                <div class="detail">{{ dataForm.sourceApp || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">用户填写城市</div>
                <div class="detail">{{ dataForm.userFillCity || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告平台定义城市</div>
                <div class="detail">{{ dataForm.platformAppointCity || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">手机号归属地</div>
                <div class="detail">{{ dataForm.phoneBelongCity || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">站点模板ID</div>
                <div class="detail">{{ dataForm.siteTempId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">站点ID</div>
                <div class="detail">{{ dataForm.siteId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">站点标题</div>
                <div class="detail">{{ dataForm.siteTitle || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">平台账套ID</div>
                <div class="detail">{{ dataForm.platformAdvertiserId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">平台账套</div>
                <div class="detail">{{ dataForm.platformAdvertiserName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告主ID</div>
                <div class="detail">{{ dataForm.advertiserId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告组（一级）</div>
                <div class="detail">{{ dataForm.adGroupName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">计划ID</div>
                <div class="detail">{{ dataForm.adPlanId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">计划（二级）</div>
                <div class="detail">{{ dataForm.adPlanName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">创意ID</div>
                <div class="detail">{{ dataForm.adOriginalityId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">创意（三级）</div>
                <div class="detail">{{ dataForm.adOriginalityName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告平台项目ID</div>
                <div class="detail">{{ dataForm.adProjectId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告平台项目（一级）</div>
                <div class="detail">{{ dataForm.adProjectName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告平台广告ID</div>
                <div class="detail">{{ dataForm.adPromotionId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">广告平台广告（二级）</div>
                <div class="detail">{{ dataForm.adPromotionName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">素材ID</div>
                <div class="detail">{{ dataForm.materialId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">视频ID</div>
                <div class="detail">{{ dataForm.videoId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">图片ID</div>
                <div class="detail">{{ dataForm.imageId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">视频名</div>
                <div class="detail">{{ dataForm.videoName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">图片名</div>
                <div class="detail">{{ dataForm.imageName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">导拍模板ID</div>
                <div class="detail">{{ dataForm.guideshootId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">导拍模板</div>
                <div class="detail">{{ dataForm.guideshootName || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">购车方式</div>
                <div class="detail">{{ dataForm.purchaseType || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">建议电销回访时间</div>
                <div class="detail">{{ dataForm.phoneVisitTime || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">品牌回传线索状态</div>
                <div class="detail">{{ dataForm.callbackStateCode || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">手机号重复次数</div>
                <div class="detail">{{ dataForm.phoneUseTimes || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">分配前经销商ID</div>
                <div class="detail">{{ dataForm.bassignDealerId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">分配后经销商ID</div>
                <div class="detail">{{ dataForm.aassignDealerId || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">线索来源</div>
                <div class="detail">{{ dataForm.clueSource || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">项目编码</div>
                <div class="detail">{{ dataForm.hjkProjectCode || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">任务编码</div>
                <div class="detail">{{ dataForm.hjkMissionCode || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">站点URL</div>
                <div class="detail">{{ dataForm.siteUrl || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">转化状态</div>
                <div class="detail">{{ dataForm.convertStatus || '-' }}</div>
            </div>
            <div class="box">
                <div class="title">微信号</div>
                <div class="detail">{{ dataForm.weixin || '-' }}</div>
            </div>
            <div class="box">
                <div class="title"></div>
                <div class="detail"></div>
            </div>
            <div class="box">
                <div class="title"></div>
                <div class="detail"></div>
            </div>
        </div>

        <a-space style="margin-top: 40px;">
            <base-button
                :title="'返回'"
                @onClickBtn="handleBack"
            ></base-button>
        </a-space>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            id: this.$route.query.id,
            dataForm: {}
        }
    },
    created() {
        this.getDetail()
    },
    methods: {
        //获取详情
        getDetail() {
            this.$api.core.pphtManage.getAdvertiserClueDetail({ id: this.id }).then((res) => {
                if(res.code == 200) {
                    let _data = res.data
                    this.$set(this, 'dataForm', _data)
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        //返回
        handleBack() {
            this.$router.push({
                path: '/clueManage'
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
}
.data-wrap {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #e8e8e8;
    .box {
        display: flex;
        width: 33.33%;
        background-color: #f7f7f7;
        &:nth-child(n+4) {
            border-top: 1px solid #929191;
        }
        & > div {
            display: flex;
            align-items: center;
            padding: 10px;
            min-height: 40px;
            box-sizing: border-box;
            word-break: break-word;
        }
        .title {
            justify-content: flex-end;
            width: 50%;
            font-weight: 500;
            color: #666;
            border-right: 1px solid #e8e8e8;
            &.red {
                color: red;
            }
        }
        .detail {
            flex: 1;
            background-color: #fff;
        }
    }
}
</style>